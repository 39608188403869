import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap/dist/gsap';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useWindowSize } from 'u9/hooks';
import { useCookie } from 'next-cookie'

import BaseLink from 'u9/components/baseLink/baseLink';

import { Layout } from './cookiePolicyWrapper.styles';

interface CookiePolicyWrapperProps {
  cookie?: any;
}

const CookiePolicyWrapper = ({ cookie = null , ...restProps }: CookiePolicyWrapperProps) => {
  const cookieSession = useCookie(cookie);
  const [session, setSession] = useState<string>(cookieSession.get('session') || null)
  const windowSize = useWindowSize();
  const { locale } = useRouter();
  const layoutRef = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    setSession('Yay!')
    cookieSession.set('session', 'Yay!');
  }
  useEffect(() => {
    gsap.to(layoutRef.current, { bottom: session == 'Yay!' ? '-160' : '0', opacity: session ? 0 : 1, duration: 0.3612, delay: session ? 0 : 3});
  }, [layoutRef, session, windowSize])
  return (
    <Layout ref={layoutRef}>
      <div>
        This website uses cookies to improve service and provide tailored ads. By using this site, you agree to this use.
      <br />
        See our&nbsp;<Link href="/cookie-policy" locale={locale}><BaseLink label={'Cookie Policy'} color={'balticSea'} onClick={()=>handleClick()} /></Link>.
      </div>
      <div onClick={() => handleClick()}>
        <BaseLink label={'Close'} color={'balticSea'} />
      </div>
    </Layout>
  );
};

export async function getServerSideProps(context) {
  const cookie = useCookie(context)
  return {
    props: {
      cookie: context.req.headers.cookie || null
    }
  }
}

export default CookiePolicyWrapper;
