import React, { useEffect } from 'react';

import * as Styled from './UnsupportedBrowser.styles';

interface UnsupportedBrowserProps {
  setAccept(state: boolean): void;
}

const UnsupportedBrowserProps = ({ setAccept }: UnsupportedBrowserProps) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <Styled.Wrapper>
      Unsupported browser
      <Styled.Button onClick={() => setAccept(true)}>
        Continue anyway
      </Styled.Button>
    </Styled.Wrapper>
  );
};

export default UnsupportedBrowserProps;
