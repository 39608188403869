import styled from 'styled-components';
import { setTypography } from 'utils/styles/mixins';
import { colors, rfs } from 'utils/styles/theme';

export const Wrapper = styled.a<{
  color: keyof typeof colors;
  active?: boolean;
  opacity?: number;
}>`
  color: ${({ color }) => colors[color]};
  position: relative;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  opacity: ${({ opacity }) => opacity}%;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: ${({ active }) => (active ? `scaleX(1)` : `scaleX(0)`)};
    height: 2px;
    bottom: -0.05em;
    left: 0;
    background-color: ${({ color }) => colors[color]};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;
