import { FlattenSimpleInterpolation } from 'styled-components';

import { getViewportInfo } from 'u9/utils/dom';
import { isBrowser, isMobile } from 'u9/utils/platform';

import * as VARS from './vars';

export const mqMobile = `(max-width: ${VARS.breakpointTablet - 1}px)`;
export const mediaMobile = (content: string) =>
  `@media ${mqMobile} {
    ${content}
  }`;

export const mqMobileSmallHeight = `(max-height: ${VARS.breakpointMobileSmallHeight}px)`;
export const mediaMobileSmallHeight = (content: string) =>
  `@media ${mqMobileSmallHeight} {
    ${content}
  }`;

export const mqTablet = `(min-width: ${VARS.breakpointTablet}px)`;
export const mediaTablet = (content: string | FlattenSimpleInterpolation) =>
  `@media ${mqTablet} {
    ${content}
  }`;

export const mqDesktopMax = `(max-width: ${VARS.breakpointDesktop}px)`;
export const mediaDesktopMax = (content: string) =>
  `@media ${mqDesktopMax} {
    ${content}
  }`;

export const mqDesktop = `(min-width: ${VARS.breakpointDesktop}px) and (min-height: ${VARS.desktopMinHeight}px)`;
export const mediaDesktop = (content: string) =>
  `@media ${mqDesktop} {
    ${content}
  }`;

const mqDesktopLarge = `(min-width: ${VARS.breakpointDesktopLarge}px)`;
export const mediaDesktopLarge = (content: string) =>
  `@media ${mqDesktopLarge} {
    ${content}
  }`;

export const mqDesktopWide = `(min-width: ${VARS.breakpointDesktop}px) and (min-aspect-ratio: ${VARS.desktopWideAspectRatio})`;
export const mediaDesktopWide = (content: string) =>
  `@media ${mqDesktopWide} {
    ${content}
  }`;

export const mqLandscape = '(orientation: landscape)';
export const mediaLandscape = (content: string) =>
  `@media ${mqLandscape} {
    ${content}
  }`;

export const mqPortrait = '(orientation: portrait)';
export const mediaPortrait = (content: string) =>
  `@media ${mqPortrait} {
    ${content}
  }`;

export const matchMobile = {
  match: isBrowser() && window.matchMedia(mqMobile),
  minFontSize: VARS.mobileMinFontSize,
  setVh: false,
};

export const matchMobileSmallHeight = {
  match: isBrowser() && window.matchMedia(mqMobileSmallHeight),
  minFontSize: VARS.mobileMinFontSize,
  setVh: false,
};

export const matchTablet = {
  match: isBrowser() && window.matchMedia(mqTablet),
  setVh: false,
};

export const matchDesktop = {
  match: isBrowser() && window.matchMedia(mqDesktop),
  setVh: false,
};

export const matchDesktopWide = {
  match: isBrowser() && window.matchMedia(mqDesktopWide),
  setVh: true,
};

export const isMobileLayout = () =>
  isMobile() || getViewportInfo().width < VARS.breakpointTablet;
