import styled from 'styled-components';
import { setTypography, calcPxToRem } from 'utils/styles/mixins';
import {
  mediaDesktop,
  mediaDesktopLarge,
  mediaMobile,
} from 'utils/styles/responsive';
import { colors, rfs } from 'utils/styles/theme';
import { createRFS } from 'utils/styles/rfs';

export const Layout = styled.div`
  background-color: ${colors.wildSand};
  color: ${colors.balticSea};
  position: fixed;
  bottom: -160;
  left: 0;
  width: 100vw;
  padding: 16px;
  padding-right: 32px;
  ${setTypography('body2')}
  display: flex;
  justify-content: center;
  column-gap: 32px;
  opacity: 0;
`;
