export default `
  @font-face {
    font-family: 'DM Sans';
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src: url('/fonts/DMSans-Regular.ttf') format('ttf');
  }

  @font-face {
    font-family: 'DM Sans';
    font-weight: 400;
    font-style: italic;
    font-display: block;
    src: url('/fonts/DMSans-RegularItalic.ttf') format('ttf');
  }

  @font-face {
    font-family: 'DM Sans';
    font-weight: 500;
    font-style: normal;
    font-display: block;
    src: url('/fonts/DMSans-Medium.ttf') format('ttf');
  }

  @font-face {
    font-family: 'DM Sans';
    font-weight: 500;
    font-style: italic;
    font-display: block;
    src: url('/fonts/DMSans-MediumItalic.ttf') format('ttf');
  }

  @font-face {
    font-family: 'DM Sans';
    font-weight: 700;
    font-style: normal;
    font-display: block;
    src: url('/fonts/DMSans-Bold.ttf') format('ttf');
  }

  @font-face {
    font-family: 'DM Sans';
    font-weight: 700;
    font-style: italic;
    font-display: block;
    src: url('/fonts/DMSans-BoldItalic.ttf') format('ttf');
  }
`;
