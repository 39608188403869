export const defaultFontSize = 16; // px

export const breakpointMobile = 425;
export const breakpointTablet = 768; // px
export const breakpointDesktop = 1024; // px
export const breakpointDesktopLarge = 1440; // px
export const breakpointDesktopExtraLarge = 1920; // px

export const desktopMinHeight = 600; // px
export const breakpointMobileSmallHeight = 600; // px

export const mobileBaseWidth = 1125; // px
export const desktopBaseWidth = 1920; // px

export const mobileMinFontSize = 16; // px
export const desktopMinFontSize = 16; // px

export const desktopWideAspectRatio = '20 / 11';
