// libraries
import React, { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import * as Sentry from '@sentry/react';
import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { SplitText } from 'gsap/dist/SplitText';
import { useWindowSize } from 'u9/hooks';
import dynamic from 'next/dynamic';
const Pixi = dynamic(() => import('u9/containers/landing/pixi'), {
  ssr: false,
});

// components
// FEAT (unused import): Language selector i18n
// import LanguageSelector from 'u9/components/LanguageSelector';
import NonFunctionals from 'u9/components/NonFunctionals/NonFunctionals';
import SideMenu from 'u9/containers/sideMenu/sideMenu';
import StandaloneVersion from 'u9/components/versioning/StandaloneVersion/StandaloneVersion';
import Version from 'u9/components/versioning/Version/Version';

// contexts
import { BaseProvider } from 'u9/contexts/base';

// styles
import GlobalStyles from 'utils/global-styles';
import theme from 'utils/styles/theme';
import CookiePolicyWrapper from 'u9/components/cookiePolicyWrapper';

// TODO (FE): enable for production. Was commented to quickfix CI/CD within FE
// initial setup
// if (process.env.ENV !== 'local') {
//   Sentry.init({
//     enabled: process.env.NODE_ENV !== 'development',
//     dsn: process.env.SENTRY_DSN,
//   });
// }

//gsap
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

const App = ({ Component, pageProps, router }: AppProps) => {
  const windowSize = useWindowSize();
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    ScrollTrigger.refresh();
  }, [windowSize]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <BaseProvider>
        {/* FEAT (unused): Language selector i18n */}
        {/* <LanguageSelector /> */}
        <NonFunctionals isBypassed>
          <Pixi />
          {/* <AnimatePresence exitBeforeEnter={true} initial={false}> */}
          <Component key={router.route} router={router} {...pageProps} />
          {/* </AnimatePresence> */}
          <SideMenu />
          <CookiePolicyWrapper />
        </NonFunctionals>
      </BaseProvider>
      {process.env.IS_DEBUG && <Version />}
      {isMounted
        ? process.env.IS_DEBUG &&
          window.location.hash === '#version' && <StandaloneVersion />
        : null}
    </ThemeProvider>
  );
};

export default App;
