import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import {isMobile, isSupportedBrowser, isDesktop, isTablet, hasWebGl } from 'u9/utils/platform';
import useWindowSize from 'u9/hooks/useWindowSize';
import { desktopMinHeight, breakpointDesktop } from 'utils/styles/vars';

import JavaScriptDisabled from './JavaScriptDisabled/JavaScriptDisabled';
import RotateDevice from './RotateDevice/RotateDevice';
import UnsupportedBrowser from './UnsupportedBrowser/UnsupportedBrowser';
import WebGLDisabled from './WebGLDisabled/WebGLDisabled';
import WindowTooSmall from './WindowTooSmall/WindowTooSmall';

interface NonFunctionalsProps {
  children: React.ReactNode | React.ReactNode[];
  isBypassed?: boolean;
}

const NonFunctionals = ({
  children,
  isBypassed = false,
}: NonFunctionalsProps): JSX.Element => {
  const [isMounted, setMounted] = useState(false);
  const [isRendered, setRendered] = useState(false);
  const [showUnsupportedBrowser, setShowUnsupportedBrowser] = useState(false);
  const [isSubStandardBrowserAccepted, setSubstandardBrowserAccepted] = useState(false);
  const [isTooSmall, setTooSmall] = useState(false);
  const [shouldRotate, setRotate] = useState(false);
  const [hasNoWebGl, setNoWebGl] = useState(false);
  const router = useRouter();

  const windowSize = useWindowSize();

  useEffect(() => {
    setMounted(true);

    if (process.env.IS_DEBUG && router.asPath.match(/^\/make-frontend-error/)) {
      throw new Error('Testing Sentry');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMounted) {
      setRendered(true);

      const { isSupported, needsUpgrade } = isSupportedBrowser();
      setShowUnsupportedBrowser(!isSupported || needsUpgrade);
      setNoWebGl(!hasWebGl());
    }
  }, [isMounted]);

  useEffect(() => {
    setTooSmall(
      isDesktop() &&
        (windowSize.width < breakpointDesktop ||
          windowSize.height < desktopMinHeight)
    );

    setRotate(
      (isMobile() && windowSize.isLandscape) ||
        (isTablet() && !windowSize.isLandscape)
    );
  }, [windowSize]);

  if (isBypassed) return <>{children}</>;

  let errorComponent = null;
  if (!isRendered) {
    errorComponent = <JavaScriptDisabled />;
  } else if (showUnsupportedBrowser && !isSubStandardBrowserAccepted) {
    errorComponent = (
      <UnsupportedBrowser setAccept={setSubstandardBrowserAccepted} />
    );
  } else if (hasNoWebGl) {
    errorComponent = <WebGLDisabled />;
  }

  return (
    <>
      {errorComponent || children}
      {isRendered && isTooSmall && <WindowTooSmall />}
      {isRendered && shouldRotate && <RotateDevice />}
    </>
  );
};

export default React.memo(NonFunctionals);
