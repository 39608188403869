import React, { forwardRef } from 'react';

import * as Styled from './baseLink.styles';
import { colors } from 'utils/styles/theme';


interface BaseLinkProps {
  color: keyof typeof colors;
  href?: string;
  label: string;
  onClick?(): void;
  active?: boolean;
  opacity?: number;
}
export interface BaseLinkRef {

}

const BaseLink = forwardRef<BaseLinkRef, BaseLinkProps>(({ color, href, label, onClick, active, opacity }, ref) => {
  return <Styled.Wrapper color={color} href={href} onClick={onClick} active={active} opacity={opacity}>{label}</Styled.Wrapper>;
});

export default BaseLink;
