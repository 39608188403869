import styled from 'styled-components';
import { calcPxToRem, setTypography } from 'utils/styles/mixins';
import { mediaMobile } from 'utils/styles/responsive';
import { colors, rfs } from 'utils/styles/theme';
import { createRFS } from 'utils/styles/rfs';

export const Layout = styled.div`
  color: ${colors.pattensBlue};
  position: fixed;
  top: 0;
  left: 0;
`;

export const Background = styled.div`
  background-color: ${colors.gravel};
  backdrop-filter: blur(10px);
  width: clamp(300px, 20%, 400px);
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: clamp(-300px, -20%, -400px);
`;

export const Foreground = styled.div`
  width: 60px;
  min-height: 100vh;
  padding: 20px 0 20px 20px;
  position: fixed;
  top: 0;
  left: -60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  > div:nth-child(n) {
    cursor: pointer;
  }

  ${mediaMobile(`
    > div:first-child {
      display: none;
    }
    > div:last-child {
      display: none;
    }
  `)}
`;

export const Nav = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding-left: 20px;
  ${rfs(`${calcPxToRem(90)}rem`, `padding-top`)}
  ${rfs(`${calcPxToRem(90)}rem`, `padding-bottom`)}
  > div:nth-child(1) {
    display: flex;
    align-items: center;
    > div:nth-child(1) {
      margin-right: 16px;
    }
  }
  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    > div:nth-child(n) {
      ${setTypography('heading6')}
      ${rfs(`${calcPxToRem(16)}rem`, `margin-bottom`)}
    }
    > div:last-child {
      margin-bottom: unset;
    }
  }
`;

export const UnderlayBack = styled.div`
  position: fixed;
  top: 0;
  left: -100%;
  min-width: 100px;
  padding: 20px 0 0 20px;
`;

export const OverlayClose = styled.div`
  position: fixed;
  top: 0;
  left: -100%;
  min-width: 100px;
  padding: 20px 0 0 20px;
`;

export const Projects = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.pattensBlue};
  cursor: pointer;
  > div:last-child {
    margin-left: 20px;
  }
`;

export const Close = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.pattensBlue};
  cursor: pointer;
  > div:last-child {
    margin-left: 20px;
  }
`;

export const SearchASolution = styled.div``;
