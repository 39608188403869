import { isBrowser } from 'u9/utils/platform';

class IntersectionService {
  static precision = 0.1;

  targets: HTMLElement[] = [];
  listeners: ((entries: IntersectionObserverEntry[]) => any)[] = [];
  observer: IntersectionObserver = null;

  constructor() {
    if (!isBrowser()) return;

    const options = {
      threshold: Array.from(
        Array(1 / IntersectionService.precision + 1).keys(),
        index => index / (1 / IntersectionService.precision)
      ),
    };

    this.observer = new IntersectionObserver(this.onObserve, options);
  }

  disconnect() {
    this.observer.disconnect();
  }

  addTarget(target: HTMLElement) {
    this.targets.push(target);
    this.observer.observe(target);
  }

  removeTarget(target: HTMLElement) {
    const targetIndex = this.targets.indexOf(target);
    if (targetIndex !== -1) {
      this.observer.unobserve(target);
      this.targets.splice(targetIndex, 1);
    }
  }

  addListener(listener: (entries: IntersectionObserverEntry[]) => any) {
    this.listeners.push(listener);
  }

  removeListener(listener: (entries: IntersectionObserverEntry[]) => any) {
    const listenerIndex = this.listeners.indexOf(listener);
    if (listenerIndex !== -1) this.listeners.splice(listenerIndex, 1);
  }

  onObserve = (entries: IntersectionObserverEntry[]) => {
    this.listeners.forEach(listener => listener(entries));
  };
}

export default new IntersectionService();
