import React, { useEffect } from 'react';

import * as Styled from './WindowTooSmall.styles';

const WindowTooSmall = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return <Styled.Wrapper>WIP!</Styled.Wrapper>;
};

export default WindowTooSmall;
