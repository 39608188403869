import { createRFS } from 'utils/styles/rfs';

const theme = {
  // "Name that Color" names
  colors: {
    primary: '#000000',

    white: '#ffffff',
    black: '#000000',
    red: '#f00f00',

    balticSea: '#302D31',
    balticSea10: '#302D3110',
    balticSea90: '#302D3190',
    balticSeaVariant: '#2C2A2D',
    blue: '#1e2ff9',
    comet: '#535B77',
    cometNum: 0x535b77,
    cornflowerBlue: '#6195ED50',
    dolly: '#EFFF8E',
    gravel: '#413D4280',
    pattensBlue: '#e0f8ff',
    pattensBlue10: '#e0f8ff10',
    pattensBlue80: '#e0f8ff80',
    rollingStone: '#777E83',
    wildSand: '#F6F6F6',
  },
  rfs: createRFS({ rfsBreakpoint: `1980px` }),
  fonts: {
    face: {
      primary:
        // eslint-disable-next-line quotes
        "'DM Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
      code:
        // eslint-disable-next-line quotes, prettier/prettier
        'Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace',
    },
    scale: {
      // Based on Material design typography scale, adjust as needed
      heading1: {
        fontSize: 103,
        letterSpacing: 1.5,
        fontWeight: 'Light',
        lineHeight: '90%',
      },
      heading2: {
        fontSize: 64,
        letterSpacing: 0.5,
        fontWeight: 'Light',
      },
      heading3: {
        fontSize: 51,
        fontWeight: 'Normal',
      },
      heading4: {
        fontSize: 36,
        letterSpacing: 0.25,
        fontWeight: 'Normal',
      },
      heading5: {
        fontSize: 26,
        fontWeight: 'Normal',
      },
      heading6: {
        fontSize: 21,
        letterSpacing: 0.15,
        fontWeight: 'Medium',
      },

      body1: {
        fontSize: 17,
        letterSpacing: 0.5,
        fontWeight: 'Normal',
      },
      body2: {
        fontSize: 15,
        letterSpacing: 0.25,
        fontWeight: 'Normal',
      },
      subtitle1: {
        fontSize: 17,
        letterSpacing: 0.15,
        fontWeight: 'Normal',
      },
      subtitle2: {
        fontSize: 15,
        letterSpacing: 0.1,
        fontWeight: 'Medium',
      },
      button: {
        fontSize: 15,
        letterSpacing: 1.25,
        fontWeight: 'Medium',
      },
      overline: {
        fontSize: 12,
        letterSpacing: 1.5,
        fontWeight: 'Normal',
      },
      caption: {
        fontSize: 13,
        letterSpacing: 0.4,
        fontWeight: 'Normal',
      },
    },
  },
} as const;

// Aliases
export const { colors, fonts, rfs } = theme;

export default theme;
