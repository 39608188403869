import React, { useEffect } from 'react';

import * as Styled from './RotateDevice.styles';

const RotateDevice = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return <Styled.Wrapper>Please rotate your device</Styled.Wrapper>;
};

export default RotateDevice;
