import React, { useContext, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { gsap } from 'gsap/dist/gsap';
import { useCycle } from 'framer-motion';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useWindowSize } from 'u9/hooks';

import { BaseContext, BaseContextType } from 'u9/contexts/base';
import BaseLink from 'u9/components/baseLink/baseLink';

import * as Styled from './sideMenu.styles';

export interface SideMenuProps {}

const SideMenu = ({ }: SideMenuProps) => {

  const { locale, pathname, back } = useRouter();
  const windowSize = useWindowSize();

  const { isMenuActive, setMenuActive, isMenuHidden, setMenuHidden, isProjectsActive, isCloseActive, isWorksActive }: BaseContextType = useContext(BaseContext);

  const sideMenuRef = useRef(null);
  const sideMenuFGRef = useRef(null);
  const sideMenuBGRef = useRef(null);
  const menuLine1Ref = useRef(null);
  const menuLine2Ref = useRef(null);

  const underlayBackRef = useRef(null);
  const overlayCloseRef = useRef(null);

  const searchASolutionRef = useRef(null);

  const menuSearchASolutionRef = useRef(null);
  const menuHomeRef = useRef(null);
  const menuOurWorkRef = useRef(null);
  const menuProjectsRef = useRef(null);
  const menuAboutUsRef = useRef(null);
  const menuPrivacyPolicyRef = useRef(null);

  useEffect(() => {
    gsap.to(sideMenuBGRef.current, {
      duration: 0.3612,
      left: isMenuActive ? '0px' : `-${sideMenuBGRef.current.getBoundingClientRect().width}px`,
    });
    gsap.to(menuLine1Ref.current, {
      duration: 0.3612,
      transformOrigin: 'center center',
      y: isMenuActive ? 5.5 : 0,
      rotate: isMenuActive ? 45 : 0,
      ease: isMenuActive ? 'back.out' : 'back.in',
    });
    gsap.to(menuLine2Ref.current, {
      duration: 0.3612,
      transformOrigin: 'center center',
      y: isMenuActive ? -5.5 : 0,
      rotate: isMenuActive ? -45 : 0,
      ease: isMenuActive ? 'back.out' : 'back.in',
    });

    gsap.to(searchASolutionRef.current, {
      duration: 0.3612,
      transformOrigin: 'center center',
      x: isMenuActive ? -50 : 0,
      ease: isMenuActive ? 'back.out' : 'back.in',
    });

    const menuItems = [
      menuSearchASolutionRef.current,
      menuHomeRef.current,
      menuOurWorkRef.current,
      menuProjectsRef.current,
      menuAboutUsRef.current,
      menuPrivacyPolicyRef.current,
    ]

    gsap.to(menuItems, {
      xPercent: isMenuActive ? 0 : -100,
      opacity: isMenuActive ? 1 : 0,
      stagger: 0.0903,
    });

  }, [isMenuActive, windowSize]);

  useEffect(() => {
    gsap.to(overlayCloseRef.current, {
      duration: 0.3612,
      left: isCloseActive ? '0px' : `-${overlayCloseRef.current.getBoundingClientRect().width}px`,
    });

  }, [isCloseActive])

  useEffect(() => {
    gsap.to(underlayBackRef.current, {
      duration: 0.3612,
      left: isProjectsActive ? '0px' : `-${underlayBackRef.current.getBoundingClientRect().width}px`,
    });

  }, [isProjectsActive])

  useEffect(() => {
    gsap.to(underlayBackRef.current, {
      duration: 0.3612,
      left: isWorksActive ? '0px' : `-${underlayBackRef.current.getBoundingClientRect().width}px`,
    });

  }, [isWorksActive])

  useEffect(() => {
    gsap.to(sideMenuFGRef.current, {
      duration: 0.3612,
      left: isMenuHidden ? `-${sideMenuFGRef.current.getBoundingClientRect().width}px` : '0px',
    });
  }, [isMenuHidden]);

  const handleClick = (path) => {

    setMenuActive(false);

    if (pathname == path) {
      return;
    }
    setMenuHidden(true);
  }

  return (
    <>
      <Styled.Layout ref={sideMenuRef}>
        <Styled.UnderlayBack ref={underlayBackRef}>
          <Link href={isProjectsActive ? "/projects" : "/our-work"} locale={locale}>
            <Styled.Projects>
              <Image
                src={'/svgs/arrow-back.svg'}
                layout="fixed"
                width={12}
                height={16}
              />
              <div>
                {isProjectsActive ? "Projects" : "Our Work"}
              </div>
            </Styled.Projects>
          </Link>
        </Styled.UnderlayBack>
        <Styled.Background ref={sideMenuBGRef}>

          <Styled.Nav>
            <div ref={menuSearchASolutionRef}>
              <Image
                src={'/svgs/search.svg'}
                layout="fixed"
                width={16}
                height={16}
                priority={true}
              />
              <Link href="/search-a-solution" locale={locale}><BaseLink label={'Search a solution'} color={'pattensBlue'}/></Link>
            </div>
            <div>
              <div ref={menuHomeRef}>
                <Link href="/" locale={locale}><BaseLink label={'Home'} color={'pattensBlue'} active={pathname === "/"} onClick={() => { handleClick("/"); }}/></Link>
              </div>
              <div ref={menuOurWorkRef}>
                <Link href="/our-work" locale={locale}><BaseLink label={'Our Work'} color={'pattensBlue'} active={pathname === "/our-work"} onClick={() => { handleClick("/our-work"); }}/></Link>
              </div>
              <div ref={menuProjectsRef}>
                <Link href="/projects" locale={locale}><BaseLink label={'Projects'} color={'pattensBlue'} active={pathname === "/projects"} onClick={() => { handleClick("/projects"); }}/></Link>
              </div>
              <div ref={menuAboutUsRef}>
                <Link href="/about-us" locale={locale}><BaseLink label={'About Us'} color={'pattensBlue'} active={pathname === "/about-us"} onClick={() => { handleClick("/about-us"); }}/></Link>
              </div>
            </div>
            <div ref={menuPrivacyPolicyRef}>
              <Link href="/privacy-policy" locale={locale}><BaseLink label={'Privacy Policy'} color={'pattensBlue'} onClick={() => { handleClick("/privacy-policy"); }}/></Link>
            </div>
          </Styled.Nav>

        </Styled.Background>
        <Styled.Foreground ref={sideMenuFGRef}>
          <div>
            <div ref={searchASolutionRef}>
              <Link href="/search-a-solution" locale={locale}>
                <Styled.SearchASolution onClick={() => { setMenuActive(false); setMenuHidden(true); }}>
                  <Image
                    src={'/svgs/search.svg'}
                    layout="fixed"
                    width={16}
                    height={16}
                    priority={true}
                  />
                </Styled.SearchASolution>
              </Link>
            </div>
          </div>
          <div onClick={() => { setMenuActive( !isMenuActive ) }}>
            <svg width="17" height="17" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line ref={menuLine1Ref} y1="2" x2="24" y2="2" stroke="#E0F8FF"strokeWidth="4"/>
              <line ref={menuLine2Ref} y1="13" x2="24" y2="13" stroke="#E0F8FF" strokeWidth="4"/>
            </svg>
          </div>
          <div>
            {/* <Image
              src={'/svgs/ideas.svg'}
              layout="fixed"
              width={18}
              height={18}
              priority={true}
            /> */}
          </div>
        </Styled.Foreground>
        <Styled.OverlayClose ref={overlayCloseRef}>
          <Styled.Close onClick={() => back()}>
            <Image
              src={'/svgs/close.svg'}
              layout="fixed"
              width={22}
              height={22}
              priority={true}
            />
            <div>
              Close
            </div>
          </Styled.Close>
        </Styled.OverlayClose>
      </Styled.Layout>
    </>
  );
};

export default SideMenu;
