import React, { createContext, ReactNode, useMemo, useState } from 'react';

interface BaseProviderProps {
  children: ReactNode | ReactNode[];
}

export interface BaseContextType {
  isMenuActive: boolean;
  setMenuActive(state: BaseContextType['isMenuActive']): void;
  isMenuHidden: boolean;
  setMenuHidden(state: BaseContextType['isMenuHidden']): void;
  isSidebarActive: boolean;
  setSidebarActive(state: BaseContextType['isSidebarActive']): void;
  isProjectsActive: boolean;
  setProjectsActive(state: BaseContextType['isProjectsActive']): void;
  isWorksActive: boolean;
  setWorksActive(state: BaseContextType['isWorksActive']): void;
  isCloseActive: boolean;
  setCloseActive(state: BaseContextType['isCloseActive']): void;
  isPIXIActive: boolean;
  setPIXIActive(state: BaseContextType['isPIXIActive']): void;
  PIXIScaleOffset: number;
  setPIXIScaleOffset(state: BaseContextType['PIXIScaleOffset']): void;


}

const defaultContext: BaseContextType = {
  isMenuActive: false,
  setMenuActive: () => null,
  isMenuHidden: true,
  setMenuHidden: () => null,
  isSidebarActive: false,
  setSidebarActive: () => null,
  isProjectsActive: false,
  setProjectsActive: () => null,
  isWorksActive: false,
  setWorksActive: () => null,
  isCloseActive: false,
  setCloseActive: () => null,
  isPIXIActive: false,
  setPIXIActive: () => null,
  PIXIScaleOffset: 1,
  setPIXIScaleOffset: () => null,
};

export const BaseContext: React.Context<BaseContextType> = createContext<BaseContextType>(defaultContext);

export const BaseProvider = ({ children }: BaseProviderProps): JSX.Element => {
  const [isMenuActive, setMenuActive] = useState<BaseContextType['isMenuActive']>(defaultContext.isMenuActive);
  const [isMenuHidden, setMenuHidden] = useState<BaseContextType['isMenuHidden']>(defaultContext.isMenuHidden);
  const [isSidebarActive, setSidebarActive] = useState<BaseContextType['isSidebarActive']>(defaultContext.isSidebarActive);
  const [isProjectsActive, setProjectsActive] = useState<BaseContextType['isProjectsActive']>(defaultContext.isProjectsActive);
  const [isWorksActive, setWorksActive] = useState<BaseContextType['isWorksActive']>(defaultContext.isWorksActive);
  const [isCloseActive, setCloseActive] = useState<BaseContextType['isCloseActive']>(defaultContext.isCloseActive);
  const [isPIXIActive, setPIXIActive] = useState<BaseContextType['isPIXIActive']>(defaultContext.isPIXIActive);
  const [PIXIScaleOffset, setPIXIScaleOffset] = useState<BaseContextType['PIXIScaleOffset']>(defaultContext.PIXIScaleOffset);

  const contextValue: BaseContextType = useMemo(
    () => ({
      isMenuActive,
      setMenuActive,
      isMenuHidden,
      setMenuHidden,
      isSidebarActive,
      setSidebarActive,
      isProjectsActive,
      setProjectsActive,
      isWorksActive,
      setWorksActive,
      isCloseActive,
      setCloseActive,
      isPIXIActive,
      setPIXIActive,
      PIXIScaleOffset,
      setPIXIScaleOffset
    }),
    [isMenuActive, setMenuActive, isMenuHidden, setMenuHidden, isSidebarActive, setSidebarActive, isProjectsActive, setProjectsActive, isWorksActive, setWorksActive, isCloseActive, setCloseActive, isPIXIActive, setPIXIActive, PIXIScaleOffset, setPIXIScaleOffset]
  );

  return (
    <BaseContext.Provider value={contextValue}>{children}</BaseContext.Provider>
  );
};
